<template>
  <div class="admin-container">
    <template v-if="user">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Users' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.first_name"
            label="Jméno *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.last_name"
            label="Příjmení *"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="roles"
            v-model="user.role"
            label="Typ uživatele *"
            item-text="name_loc"
            item-value="name"
          ></v-select
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.phone_number"
            label="Telefonní číslo *"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field v-model="user.email" label="E-mail *"></v-text-field
        ></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Users' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="createUser">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from "../plugins/bus";
import users from "../api/userService";
import authHandler from "../api/authHandler";
import userService from "../api/userService";

export default {
  data() {
    return {
      user: {},
      roles: [],
    };
  },
  async created() {
    await this.getRoles();
  },
  methods: {
    async getRoles() {
      try {
        this.roles = await userService.getRoles();
      } catch (error) {
        throw error;
      }
    },
    async createUser() {
      try {
        bus.$emit("processing");
        const response = await users.createUser(this.user);
        console.debug("Response", response);
        bus.$emit("processing", false);
        bus.$emit("snackbar", {
          text: "Uloženo",
          color: "success",
        });
        this.$router.push({ name: "Users"});
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  watch: {},
};
</script>

<style></style>
